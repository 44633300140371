<template >
  <div style="margin-top:0rem" class="content">
    <div class="headArea">
      <img :src="imgSrc" style="width:100%;height:100%" />
      <div class="headLine">
        <div class="headLineLeft">
          <div class="blueArea"></div>
          <div class="titleFont">泰顺廊桥氡泉旅游度假区</div>
        </div>
        <div class="headLineRight">{{headDate}}</div>
      </div>
    </div>
    <div style="width:100%;height:0.04rem"></div>
    <div class="tabLine">
      <div class="leftTab">
        <div class="contentLine">
          <img src="./imgs/weather.png" class="tabIcon" />
          <div class="tabFontLine">
            <div class="tabTitleText">天气</div>
            <div class="tabContentText">{{analysisData.weather}}</div>
          </div>
        </div>
      </div>
      <div class="rightTab">
        <div class="contentLine">
          <img src="./imgs/windDirection.png" class="tabIcon" />
          <div class="tabFontLine">
            <div class="tabTitleText">风向</div>
            <div class="tabContentText">{{analysisData.windDirection}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabLine">
      <div class="leftTab">
        <div class="contentLine">
          <img src="./imgs/humidity.png" class="tabIcon" />
          <div class="tabFontLine">
            <div class="tabTitleText">相对湿度</div>
            <div class="tabContentText">{{analysisData.humidity}}</div>
          </div>
        </div>
      </div>
      <div class="rightTab">
        <div class="contentLine">
          <img src="./imgs/temperature.png" class="tabIcon" />
          <div class="tabFontLine">
            <div class="tabTitleText">温度</div>
            <div class="tabContentText">{{analysisData.temporation}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabLine">
      <div class="leftTab">
        <div class="contentLine">
          <img src="./imgs/windForce.png" class="tabIcon" />
          <div class="tabFontLine">
            <div class="tabTitleText">风力等级</div>
            <div class="tabContentText">{{analysisData.windLevel}}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Notify } from 'vant';
export default {
  name: 'weather',

  data() {
    return {
      imgSrc: '',
      headDate: '10/12.12',
      analysisData: {}
    };
  },
  watch: {},
  created() {
    this.getRecords();
    this.getMultipleCount();
    this.getWeather();
  },
  destroyed() {
    // window.removeEventListener('hashchange', this.afterQRScan)
  },
  methods: {
    getRecords() {
      const this_ = this;
      this_.$api
        .getContentByPage({ topicSymbol: 'min-home-banner' })
        .then(res => {
          if (res.data.code === 10000) {
            this_.imgSrc =
              res.data.data.records.length > 0
                ? res.data.data.records[0].content.bannerPic
                : '';
          } else {
            Notify({ type: 'warning', message: res.data.msg });
          }
        });
    },
    getWeather() {
      const this_ = this;
      this_.$ajax
        .get(
          `https://restapi.amap.com/v3/weather/weatherInfo?key=c153e7e4ebba941cce3e935249a52da6&city=330329`
        )
        .then(res => {
          if (res.data.status == '1') {
            const data = res.data.lives[0];
            const analysisData = {
              weather: data.weather,
              windDirection: data.winddirection,
              humidity: data.humidity,
              temporation: data.temperature,
              windLevel: data.windpower
            };
            this_.analysisData = analysisData;
          } else {
            Notify({ type: 'warning', message: '获取不到天气信息' });
          }
        });
    },
    getMultipleCount() {
      const that = this;
      // 获取时间
      let headDate = '';
      const date = new Date();
      const xingqi = that.getDay(date.getDay());
      headDate = `${date.getMonth() + 1}月${date.getDate()}日(${xingqi})`;
      that.headDate = headDate;
    },
    getDay(number) {
      let date = '';
      switch (number) {
        case 0:
          date = '周日';
          break;
        case 1:
          date = '周一';
          break;
        case 2:
          date = '周二';
          break;
        case 3:
          date = '周三';
          break;
        case 4:
          date = '周四';
          break;
        case 5:
          date = '周五';
          break;
        case 6:
          date = '周六';
          break;
        default:
          date = '周六';
      }
      return date;
    }
  }
};
</script>
<style scoped>
.content {
  width: 100%;
  height: 100%;
  position: relative;
}
.headArea {
  height: 5.43rem;
  width: 100%;
  background-color: gray;
  position: relative;
}

.headLine {
  height: 0.56;
  width: 100%;
  position: absolute;
  bottom: 0.36rem;
  left: 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.headLineLeft {
  margin-left: 0.32rem;
  width: 4.67rem;
  display: flex;
  align-items: center;
}

.headLineRight {
  margin-right: 0.32rem;
  display: flex;
  align-items: center;
  font-size: 0.347rem;
  color: #fff;
}
.blueArea {
  width: 0.107rem;
  height: 0.427rem;
  background-color: #00a5ff;
  opacity: 1;
}

.titleFont {
  width: 4.4rem;
  height: 0.56rem;
  margin-left: 0.093rem;
  font-size: 0.4rem;
  color: #fff;
  font-weight: 500;
}
.tabLine {
  height: 2.2rem;
  width: 100%;
  margin-top: 0.32rem;
  display: flex;
  justify-content: space-between;
}

.leftTab {
  height: 100%;
  width: 4.52rem;
  margin-left: 0.32rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
}

.rightTab {
  height: 100%;
  width: 4.52rem;
  margin-right: 0.32rem;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0rem 0.027rem 0.16rem 0rem rgba(0, 0, 0, 0.14);
  border-radius: 0.213rem;
}

.contentLine {
  height: 1.333rem;
  margin-top: 0.413rem;
  margin-left: 0.533rem;
  display: flex;
}

.tabIcon {
  width: 1.333rem;
  height: 100%;
}

.tabFontLine {
  height: 100%;
  width: 1.733rem;
  margin-left: 0.267rem;
}

.tabTitleText {
  margin-top: 0.08rem;
  width: 1.4rem;
  height: 0.493rem;
  font-size: 0.347rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(136, 136, 136, 1);
  line-height: 0.493rem;
}

.tabContentText {
  margin-top: 0.107rem;
  width: 1.6rem;
  height: 0.56rem;
  font-size: 0.4rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: rgba(51, 51, 51, 1);
  line-height: 0.56rem;
}
</style>



